<div class="col-12 login-container">
  <form *ngIf="config$ | async as config" class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="title">
      <span class="text">{{ config?.productName }}</span>
    </div>

    <div *ngIf="loginError">
      <div class="alert alert-danger" data-test="login-alert">
        {{ 'auth.sign-in.error' | translate }}
      </div>
    </div>

    <div>
      <cap-input
        formControlName="email"
        [label]="'auth.sign-in.email' | translate"
        [placeholder]="'auth.sign-in.email' | translate"
        styleClass="w-100"
        data-test="email">
      </cap-input>

      <cap-password
        formControlName="password"
        [label]="'auth.sign-in.password' | translate"
        [placeholder]="'auth.sign-in.password' | translate"
        styleClass="w-100"
        [toggleMask]="config.displayPassword"
        [hideIcon]="config.displayPassword?.hideIcon"
        [showIcon]="config.displayPassword?.showIcon"
        [autoCloseTimeout]="config.displayPassword?.autoCloseTimeout">
      </cap-password>
    </div>

    <div class="login-actions d-flex align-items-center">
      <cap-button
        type="submit"
        [disabled]="loginBusy"
        styleClass="primary"
        data-test="login-button"
        [label]="'auth.sign-in.button' | translate">
      </cap-button>

      <span class="login-separator">{{ 'auth.sign-in.or-label' | translate }}</span>

      <a routerLink="/auth/forgot-password" class="login-link" data-test="forgot-password-link">
        {{ 'auth.sign-in.password-forgot' | translate }}
      </a>
    </div>
  </form>
</div>
