export enum CustomColumn {
  quantity = 'quantity',
  unit = 'unit',
  remarks = 'remarks',
  customerPrice = 'customer_price',
  netPrice = 'net_price',
  productDetails = 'product_details',
  productName = 'product_name',
  priceInput = 'price-input',
  checkbox = 'checkbox',
  price = 'price',
  customerUnitPrice = 'customer_unit_price',
  actions = 'action-menu',
  driverName = 'name',
  loadingDate = 'loading_date',
  location = 'location',
  productLocation = 'productLocation',
  comment = 'comment',
  split = 'split',
  undo = 'undo',
  splitQuantity = 'splitQuantity',
  splitUnit = 'splitUnit',
  palletType = 'palletType',
  estimation = 'estimation',
  actual = 'actual',
  disapproveReason = 'disapproveReason',
  quantityToPrefill = 'quantityToPrefill',
  negativeAmount = 'negativeAmount',
}
