import { NgModule } from '@angular/core';
import { CustomLoginComponent } from './custom-login.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumPasswordModule } from '@capturum/ui/password';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomLoginComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    CapturumButtonModule,
    CapturumInputModule,
    CapturumPasswordModule,
    RouterModule,
  ],
})
export class CustomLoginModule {}
